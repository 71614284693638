<template>
  <div class="app__login">
    <div class="app__login--clever"></div>
    <div class="app__login--form">
      <div style="position: relative; min-width: 400px">
        <div id="logo"></div>
        <reg-form></reg-form>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import RegForm from "@/components/layout/reg-form";

export default {
  name: "Registration",
  components: { RegForm }
};
</script>
